exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photography-index-tsx": () => import("./../../../src/pages/photography/index.tsx" /* webpackChunkName: "component---src-pages-photography-index-tsx" */),
  "component---src-pages-photography-leaving-home-tsx": () => import("./../../../src/pages/photography/leaving_home.tsx" /* webpackChunkName: "component---src-pages-photography-leaving-home-tsx" */),
  "component---src-pages-photography-to-vanish-tsx": () => import("./../../../src/pages/photography/to_vanish.tsx" /* webpackChunkName: "component---src-pages-photography-to-vanish-tsx" */),
  "component---src-pages-photography-transient-tsx": () => import("./../../../src/pages/photography/transient.tsx" /* webpackChunkName: "component---src-pages-photography-transient-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-article-tsx-content-file-path-src-articles-01-manim-intro-manim-intro-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/homepage/homepage/src/articles/01_manim_intro/manim_intro.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-articles-01-manim-intro-manim-intro-md" */),
  "component---src-templates-article-tsx-content-file-path-src-articles-02-maki-atoms-and-time-travel-maki-atoms-and-time-travel-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/homepage/homepage/src/articles/02_maki_atoms_and_time_travel/maki_atoms_and_time_travel.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-articles-02-maki-atoms-and-time-travel-maki-atoms-and-time-travel-md" */),
  "component---src-templates-article-tsx-content-file-path-src-articles-03-installing-gentoo-installing-gentoo-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/homepage/homepage/src/articles/03_installing_gentoo/installing_gentoo.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-articles-03-installing-gentoo-installing-gentoo-md" */),
  "component---src-templates-article-tsx-content-file-path-src-articles-04-revealjs-revealjs-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/homepage/homepage/src/articles/04_revealjs/revealjs.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-articles-04-revealjs-revealjs-md" */),
  "component---src-templates-article-tsx-content-file-path-src-articles-05-compare-view-example-compare-view-example-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/homepage/homepage/src/articles/05_compare_view_example/compare_view_example.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-articles-05-compare-view-example-compare-view-example-md" */),
  "component---src-templates-article-tsx-content-file-path-src-articles-06-bash-cmds-01-mp-3-tag-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/homepage/homepage/src/articles/06_bash_cmds/01_mp3_tag.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-articles-06-bash-cmds-01-mp-3-tag-md" */),
  "component---src-templates-article-tsx-content-file-path-src-articles-07-lvim-in-distrobox-lvim-in-distrobox-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/homepage/homepage/src/articles/07_lvim_in_distrobox/lvim_in_distrobox.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-src-articles-07-lvim-in-distrobox-lvim-in-distrobox-md" */),
  "component---src-templates-projects-language-tsx": () => import("./../../../src/templates/projects_language.tsx" /* webpackChunkName: "component---src-templates-projects-language-tsx" */)
}

